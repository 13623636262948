.checkCircle {
    color: rgb(22, 185, 50);
    font-size: 22px;
}

.exclamationTriangle {
    color: rgb(230, 20, 20);
    font-size: var(--p-size);
    /* font-size: 22px; */
}

.times {
    font-size: 14px;
    cursor: pointer;
    position: relative;
    top: -8px;
}

@media (max-width: 768px) {

    .checkCircle {
        font-size: 18px;
    }
    
    .exclamationTriangle {
        font-size: 16px;
        /* font-size: 22px; */
    }
    
    .times {
        font-size: 14px;
        top: -10px;
    }
}

@media (max-width: 480px) {

    .checkCircle {
        font-size: 16px;
    }
    
    .exclamationTriangle {
        font-size: 14px;
        /* font-size: 22px; */
    }
    
    .times {
        font-size: 12px;
        top: -10px;
    }
}