@font-face {
  font-family: 'JosefinSans';
  src: local('JosefinSans'), url(./assets/JosefinSans-SemiBold.ttf) format('truetype');
}

:root {
  --color-text: black;
  --color-background: lightgray;
  --p-size: 18px;
  --h2-size: 24px;
  --icon-color: #333;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'JosefinSans';
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
