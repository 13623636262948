.welcomeHeader {
    height: 55px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

h1 {
    font-weight: 800;
}

.iconDiv {
    width: 150px;
    /* background: chartreuse; */
    /* display: flex;
    /* justify-content: space-between; */
    /* align-items: center;  */
}

.iconDivSecret {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 150px;
    cursor: pointer;
    /* background: chartreuse; */
    /* display: flex;
    /* justify-content: space-between; */
    /* align-items: center;  */
}

.iconContainer {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    /* cursor: pointer; */
}

.icon {
    color: rgba(255, 255, 255, 0.9);
    font-size: 24px;
}

.guestDiv {
    display: flex;
}

.guestDiv p {
    font-weight: 600;
    text-align: center;
}

.iconDiv div {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.iconDiv p {
    font-size: var(--p-size);
    margin-left: 10px;
    font-weight: 600;
}

.chevronIcon {
    font-size: var(--p-size);
    color: var(--icon-color);
}

@media (max-width: 768px) {

    .welcomeHeader {
        padding: 0 10px;
    }

    h1 {
        font-size: 28px;
    }

    .iconDiv {
        width: 120px;
        /* background: chartreuse; */
        /* display: flex;
    /* justify-content: space-between; */
        /* align-items: center;  */
    }

    .iconDiv div p{
       font-size: 16px;
    }

    .iconDivSecret {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 120px;
        cursor: pointer;
        /* background: chartreuse; */
        /* display: flex;
    /* justify-content: space-between; */
        /* align-items: center;  */
    }

    .iconContainer {
        height: 34px;
        width: 34px;
        /* cursor: pointer; */
    }

    .icon {
        font-size: 20px;
    }

 
    .guestDiv p {
        font-size: 16px;
        margin-right: 10px;
    }

    .iconDiv div {
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .iconDiv p {
        font-size: var(--p-size);
        margin-left: 10px;
        font-weight: 600;
    }

    .chevronIcon {
        font-size: var(--p-size);
        color: var(--icon-color);
    }
}


@media (max-width: 570px) {

    .welcomeHeader {
        height: auto;
        padding: 10px 5px;
    }

    h1 {
        font-size: 24px;
        text-align: center;
    }

    .iconDiv {
        width: 120px;
        /* background: red; */
        /* display: flex;
    /* justify-content: space-between; */
        /* align-items: center;  */
    }

    .iconDiv div p{
       font-size: 14px;
    }

    .iconDivSecret {
        width: 120px;
        /* background-color: greenyellow; */
    }

    .iconContainer {
        height: 30px;
        width: 30px;
        /* cursor: pointer; */
    }

    .icon {
        font-size: 18px;
    }

 
    .guestDiv p {
        font-size: 14px;
        margin-right: 4px;
    }

    /* .iconDiv p {
        font-size: var(--p-size);
        margin-left: 10px;
        font-weight: 600;
    } */

    .chevronIcon {
        font-size: 16px;
    }
}


@media (max-width: 420px) {

    .welcomeHeader {
        height: auto;
        padding: 10px 5px;
    }

    h1 {
        font-size: 20px;
        text-align: center;
    }

    .iconDiv {
        width: 40px;
        /* background: red; */
        /* display: flex;
    /* justify-content: space-between; */
        /* align-items: center;  */
    }

    .iconDiv div p{
       font-size: 14px;
       display: none;
    }

    .iconDivSecret {
        width: 40px;
    }

    .iconContainer {
        height: 30px;
        width: 30px;
        /* cursor: pointer; */
    }

    .icon {
        font-size: 18px;
    }

 
    .guestDiv p {
        font-size: 14px;
        display: none;
        margin-right: 10px;
    }

    /* .iconDiv p {
        font-size: var(--p-size);
        margin-left: 10px;
        font-weight: 600;
    } */

    .chevronIcon {
        font-size: 16px;
    }
}

