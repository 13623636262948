.Today {
    display: flex;
    flex-direction: column;
    padding: 5px 40px;
    color: #f0f0f0;
    width: 100%;
    height: 100vh;
}

.DateNow {
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 38px;
    font-weight: 500;
    border-radius: 10px;
    /* width: 500px; */
    max-width: 500px;
    /* height: 60px; */
    padding: 0 30px;
    line-height: 60px;
    text-align: center;
}

.TodayTask {
    margin: 10px 0;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 20px;
    padding: 10px;
    border-radius: 10px;
    width: 80px;
    text-align: center;
}

.AddTodayTask {
    display: flex;
    flex-direction: column;
    /* background-color: royalblue; */
    height: 70%;
    padding: 4px 0;
    width: 80%;
}

.AddTask {
    background-color: #e6e6e6;
    border-radius: 10px;
    height: 42px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    border: 1px solid rgba(0, 0, 0, 0.7);
}

.Icon {
    margin-right: 10px;
    color: black;
}

.Icon:hover{
    cursor: pointer;
}

.Input {
    width: 100%;
    height: 100%;
    background-color: none;
    border: none;
    background-color: #e6e6e6;
    outline: none;
}

.TaskContainer {
    /* display: flex;
    flex-direction: column; */
    /* background-color: rgba(0, 0, 0, 0.7); */
    height: 258px;
    overflow-y: auto;
}

.Task {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e6e6e6;
    border-radius: 10px;
    min-height: 42px;
    padding: 0 12px;
    border: 1px solid rgba(0, 0, 0, 0.7);
    color: black;
    margin: 8px 0;
}

.TaskInner {
    display: flex;
    align-items: center;
    width: 90%;
}

.PTask {
    margin: 0;
    padding: 4px;
    line-height: 22px;
}

.PTaskDone {
    text-decoration: line-through;
}

@media (max-width: 768px) {

    .Today {
        height: calc(100vh - 40px);
        align-items: flex-end;
        padding: 25px 40px 10px;
    }

    .DateNow {
        font-size: 34px;
        /* width: 500px; */
        max-width: 500px;
        /* height: 55px; */
        padding: 0 30px;
        line-height: 55px;
    }

    .Input::placeholder {
        font-size: 18px;
    }
    
}

@media (max-width: 480px) {

    .Today {
        height: calc(100vh - 30px);
        align-items: flex-end;
        padding: 20px 20px 10px;
    }

    .DateNow {
        font-size: 24px;
        /* width: 500px; */
        max-width: 400px;
        /* height: 55px; */
        padding: 0 20px;
        line-height: 50px;
    }

    .TaskContainer {
        /* display: flex;
        flex-direction: column; */
        /* background-color: rgba(0, 0, 0, 0.7); */
        height: 258px;
        overflow-y: auto;
    }

    .AddTodayTask {
        display: flex;
        flex-direction: column;
        /* background-color: royalblue; */
        max-height: 50%;
        padding: 4px 0;
        width: 90%;
    }

    .AddTask {
        height: 42px;
        padding: 0 8px;
    }

    .Task {
        min-height: 34px;
        padding: 0 8px;
        margin: 8px 0;
    }

    .Input {
        width: 100%;
        height: 100%;
    }

    .Input::placeholder {
        font-size: 18px;
    }

    .Icon {
        margin-right: 6px;
        color: black;
        font-size: 12px;
    }

    .PTask {
        margin: 0;
        padding: 4px 0;
        line-height: 22px;
    }

    .DivDateAndTrash {
        min-width: 70px;
        /* background-color: lawngreen; */
    }

    .DivDateAndTrash span {
        font-size: 12px;
    }
}