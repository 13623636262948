.Main {
    background-image: url('../../assets/cool-background.png');
    background-position: center;
    display: flex;
    
}

.HeaderToogle {
    display: none;
}

.Header {
    width: 330px;
    height: 100vh;
    background-color: #e6e6e6;
    display: flex;
    flex-direction: column;
}

.UserDiv {
    display: flex;
    justify-content: flex-start;
    /* justify-content: space-between; */
    align-items: center;
    border-bottom: 1px solid #a0a0a0;
    width: 100%;
    height: 70px;
    padding: 10px;
    padding-left: 20px;
    position: relative;
    
}

.User {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: cornflowerblue;
    width: 38px;
    height: 38px;
}

.IsUser {
    cursor: pointer;
}

.Dropdown {
    position: absolute;
    padding: 8px 12px;
    top: 50px;
    left: 2px;
    /* border: 1px solid #a0a0a0; */
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #f0f0f0;
    cursor: pointer;
}

.Dropdown p {
    font-weight: 600;
}

.UserP {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
    max-width: 162px;
    width: 162px;
    text-align: center;
    /* background: greenyellow; */
}

.Icon {
    font-size: 20px;
    color: whitesmoke;
}

.times {
    display: none;
}

.TodayDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 10px;
    padding-left: 20px;
}

.TodayDiv:hover {
    cursor: pointer;
    background-color: whitesmoke;
}

.TodayDivActive {
    background-color: whitesmoke;
}

.IconDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    /* background-color: cornflowerblue; */
    width: 38px;
    height: 38px;
}

.FolderIcon {
    font-size: 22px;
    color: #ff8800;
}

.SunIcon {
    font-size: 28px;
    color: #ffcc00;
}

.TomorrowIcon {
    font-size: 24px;
    color: #339933;
}

.WeekIcon {
    font-size: 24px;
    color: #6600cc;
}

.ScheduleDiv {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 18px;
}

.DatePickerDiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding-bottom: 20px;
}


@media (max-width: 768px) {
    .Main {
        display: flex;
        flex-direction: column;
    }

    .Header {
        width: 0px;
        height: 100vh;
        background-color: #e6e6e6;
        flex-direction: column;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
    }

    .HeaderShow {
        width: 200px;
        visibility: visible;
        opacity: 1;
    }

    .HeaderToogle {
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0 25px 45px rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        height: 40px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 16px;
    }

    .HamburguerContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.7);
        width: 28px;
        height: 28px;
        border-radius: 5px;
        cursor: pointer;
    }

    .IconHamburguer {
        color: rgba(255, 255, 255, 0.9);
        font-size: 20px;
    }

    .times {
        display: block;
        font-size: 14px;
        cursor: pointer;
        position: relative;
        top: -16px;
        right: -10px;
        z-index: 10;
    }

    .UserP {
        margin-left: 10px;
        max-width: 110px;
        width: 100px;
        text-align: center;
        /* background: greenyellow; */
    }
}

@media (max-width: 480px) {

    .HeaderShow {
        width: 150px;
        visibility: visible;
        opacity: 1;
    }

    .HeaderToogle {
        height: 30px;
        padding: 0 12px;
    }

    .HamburguerContainer {
        width: 22px;
        height: 22px;
    }

    .IconHamburguer {
        font-size: 15px;
    }

    .times {
        display: block;
        font-size: 12px;
        top: -14px;
        right: -14px;
    }

    .UserDiv {
        height: 50px;
        padding-left: 12px;
        /* background: hotpink; */
        padding-right: 5px;
    }

    .User {
        width: 30px;
        height: 30px;
    }

    .UserP {
        margin-left: 8px;
        max-width: 80px;
        width: 70px;
        font-size: 16px;
        /* background: greenyellow; */
    }

    .Dropdown {
        position: absolute;
        padding: 8px 12px;
        top: 50px;
        left: 2px;
        /* border: 1px solid #a0a0a0; */
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.7);
        color: #f0f0f0;
        cursor: pointer;
    }

    .Dropdown p {
        font-size: 16px;
    }

    .Icon {
        font-size: 18px;
    }

    .TodayDiv {
        height: 50px;
        padding: 10px;
        padding-left:12px;
    }

    .IconDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        /* background-color: cornflowerblue; */
        width: 38px;
        height: 38px;
    }

    .ScheduleDiv span {
        font-size: 16px;
    }

    .FolderIcon {
        font-size: 17px;
    }

    .SunIcon {
        font-size: 22px;
    }

    .TomorrowIcon {
        font-size: 20px;
    }

    .WeekIcon {
        font-size: 20px;
    }

    .ScheduleDiv {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        font-size: 14px;
    }

    .DatePickerDiv {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        padding-bottom: 25px;
        width: 100%;
        /* background: indianred; */
    }

    .DatePicker {
        font-size: 10px;
    }
}