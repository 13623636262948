.stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2rem 0;
    margin: 0 -5%;
    overflow: hidden;
    width: 100%;
  }

  
.dotPulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000;
    color: #000;
    box-shadow: 9999px 0 0 -5px #000;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
  }
  
  .dotPulse::before, .dotPulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000;
    color: #000;
  }
  
  .dotPulse::before {
    box-shadow: 9984px 0 0 -5px #000;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }
  
  .dotPulse::after {
    box-shadow: 10014px 0 0 -5px #000;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
  }
  
  @keyframes dotPulseBefore {
    0% {
      box-shadow: 9984px 0 0 -5px #000;
    }
    30% {
      box-shadow: 9984px 0 0 2px #000;
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px #000;
    }
  }
  
  @keyframes dotPulse {
    0% {
      box-shadow: 9999px 0 0 -5px #000;
    }
    30% {
      box-shadow: 9999px 0 0 2px #000;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px #000;
    }
  }
  
  @keyframes dotPulseAfter {
    0% {
      box-shadow: 10014px 0 0 -5px #000;
    }
    30% {
      box-shadow: 10014px 0 0 2px #000;
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px #000;
    }
  }
  